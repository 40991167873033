// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Components } from 'haworth-service-bar';

export class TokenService {
  public getBar = async () => {
    const bar = document.querySelector('service-bar') as HTMLServiceBarElement;
    return bar;
  };

  public requestLogin = async () => {
    const bar = await this.getBar();
    const event = new Event('requestSBUserLogin');
    bar.dispatchEvent(event);
  };

  public getUser = async () => {
    const bar = await this.getBar();
    return bar.getUser();
  };

  public getToken = async () => {
    const user = await this.getUser();
    if (user) {
      return user.access_token;
    } else {
      return null;
    }
  };
}
