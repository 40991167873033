const errorsTypes: ErrorTypes = {
  network: 'Network Error',
  cancelRequest: 'Cancel Request'
};

interface ErrorTypes {
  network: string;
  cancelRequest: string;
}

export default errorsTypes;
