import React, { useEffect } from 'react';
import { models, service, factories } from 'powerbi-client';
import './PowerBIReportEmbedded.scss';
import { config } from '../../shared/config';
import { ApiService } from '../../shared/http';
import { EmbedParams } from './PowerBIReportModel';

const PowerBIReportEmbedded = function() {
  const getEmbeddedInfo = async () => {
    const apiService = new ApiService();
    const response = await apiService.get<EmbedParams>('api/v1/PowerBIReportEmbedded');
    const info = response.data;
    return {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: info.EmbedToken.Token,
      // You can embed different reports as per your need
      embedUrl: info.EmbedReport[0].EmbedUrl,
      // Enable this setting to remove gray shoulders from embedded report
      settings: {
        background: models.BackgroundType.Transparent
      }
    };
  };

  useEffect(() => {
    getEmbeddedInfo().then(info => {
      const powerBi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
      const container = document.getElementById('powerbi-report');
      if (container !== null) {
        // Embed Power BI report when Access token and Embed URL are available
        const report = powerBi.embed(container, info);

        // Clear any other loaded handler events
        report.off('loaded');

        // Triggers when a report schema is successfully loaded
        report.on('loaded', function() {
          console.log('Report load successful');
        });

        // Clear any other rendered handler events
        report.off('rendered');

        // Triggers when a report is successfully embedded in UI
        report.on('rendered', function() {
          console.log('Report render successful');
        });

        // Clear any other error handler events
        report.off('error');

        // Handle embed errors
        report.on('error', function(event) {
          const errorMsg = event.detail;

          // Use errorMsg variable to log error in any destination of choice
          console.log(errorMsg);
          return;
        });
      }
    });
  }, []);

  return <div id="powerbi-report" className="responsive-iframe"></div>;
};

export default PowerBIReportEmbedded;
