import ApiService from '../../shared/http/ApiService';

export class ServiceTicketsService {
  private readonly apiService: ApiService;
  private readonly createEmailEndpoint = 'api/v1/ServiceTickets/CreateEmail';

  constructor() {
    this.apiService = new ApiService();
  }

  public async postSupportTicketEmail(form: FormData): Promise<void> {
    // console.log(form);
    await this.apiService.post(this.createEmailEndpoint, form);
  }
}
