import React, { useEffect } from 'react';
import ServiceTicket from './ServiceTicket';
import Modal from 'react-modal';
import { Location as LocationRouter } from 'history';
import { useHistory, useLocation } from 'react-router-dom';

const ServiceTicketModal = () => {
  const location = useLocation();
  const history = useHistory();
  const modalStyles: Modal.Styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 41, 59, 0.81)',
      zIndex: 99999
    },
    content: {
      position: 'relative',
      width: '84rem',
      minHeight: '18rem',
      margin: '4rem auto 0',
      border: '1px solid #ccc',
      background: '#fff',
      height: 'calc(95vh - 40px)',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      inset: '0',
      borderRadius: '4px',
      outline: 'none',
      padding: '0',
      zIndex: 999999
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const getMainRoute = (location: LocationRouter<{}>) => {
    const { pathname } = location;
    const detailsRoute = '/contact';
    const newRoute = pathname.substr(0, pathname.indexOf(detailsRoute));
    return newRoute;
  };
  const onCloseHandler = () => {
    document.body.style.overflow = 'unset';
    history.push(getMainRoute(location as LocationRouter<{}>));
  };

  return (
    <Modal isOpen={true} style={modalStyles}>
      <ServiceTicket onCloseHandler={onCloseHandler} />
    </Modal>
  );
};

export default ServiceTicketModal;
