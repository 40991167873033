import React from 'react';

const UserContext = React.createContext<UserContextData | null>(null);

export { UserContext };

export interface UserContextData {
  userCompany: string | null;
  userEmail: string | null;
  userName: string | null;
  userAvatar: string | null;
}
