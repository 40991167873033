import React from 'react';

const LangContext = React.createContext<LangContextData | null>(null);

export { LangContext };

export interface LangContextData {
  defaultLanguage: string;
  defaultLanguages: string[];
}
