/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import HttpClientBase from './ApiServiceBase';
import HttpClientCancelToken from './AxiosCancelToken';
import { TokenService } from '../auth';

export class ApiService extends HttpClientBase {
  private tokenService: TokenService | null = null;

  constructor() {
    super(axios);
    this.tokenService = new TokenService();
  }

  public async get<T>(
    url: string,
    queryParams: Record<string, string> = {},
    cancelToken?: HttpClientCancelToken
  ): Promise<AxiosResponse<T>> {
    try {
      const accessToken = await this.getAuthorizationHeader();
      let headers = {};
      if (accessToken) {
        headers = { Authorization: accessToken };
      }
      return super.get<T>(url, queryParams, cancelToken, headers);
    } catch (e) {
      throw new Error(`Error: ${e.status}`);
    }
  }

  public async post<T>(
    url: string,
    body: any,
    cancelToken?: HttpClientCancelToken
  ): Promise<AxiosResponse<T>> {
    try {
      const accessToken = await this.getAuthorizationHeader();
      let headers = {};
      if (accessToken) {
        headers = { Authorization: accessToken };
      }
      return super.post<T>(url, body, cancelToken, headers);
    } catch (e) {
      throw new Error(`Error: ${e.status}`);
    }
  }

  private async getAuthorizationHeader(): Promise<string | null> {
    let headers = null;
    if (this.tokenService) {
      const accessToken = await this.tokenService.getToken();
      if (accessToken) {
        headers = `bearer ${accessToken}`;
      }
    }
    return headers;
  }
}

export default ApiService;
