import SubNav from '../subnav/SubNav';
import React, { Suspense, useContext } from 'react';
import './Layout.scss';
import { Loader } from './Loader';
import { ServiceFooter } from '../../shared/layout/ServiceFooter';
import { LangContext } from '../../shared/LangContext';
import { config } from '../../shared/config/Config';

const Layout = (props: React.PropsWithChildren<{}>) => {
  const lang = useContext(LangContext);
  return (
    <Suspense fallback={<Loader />}>
      <SubNav />
      <div className="main__content">{props.children}</div>
      <ServiceFooter
        defaultLanguage={lang?.defaultLanguage ?? 'en'}
        settingsUri={config.get('serviceBarSettingsUri') as string}
      />
    </Suspense>
  );
};

export default Layout;
