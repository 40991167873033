import React from 'react';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { Tile } from '../../../shared/models/tile';
import './Tile.scss';
import { Link } from 'react-router-dom';

const TileBase: React.FunctionComponent<React.PropsWithChildren<{ tileConfig: Tile }>> = props => {
  const { t } = useTranslation();
  const { tileConfig: tile } = props;
  const button = tile.buttons[0];
  const iconStyle = {
    color: tile.textColor,
    backgroundColor: tile.backgroundColor
  };
  if (tile.isActive) {
    if (tile.key === 'contact') {
      return (
        <Link key={button.translationKey} className="tile" to="/contact">
          <div className="tile--icon" style={iconStyle}>
            <SVG src={tile.iconData} />
          </div>
          <div className="tile--content">
            <h1>{t(`tiles.title.${tile.key}`)}</h1>
          </div>
        </Link>
      );
    } else {
      return (
        <a key={button.translationKey} className="tile" href={button.url}>
          <div className="tile--icon" style={iconStyle}>
            <SVG src={tile.iconData} />
          </div>
          <div className="tile--content">
            <h1>{t(`tiles.title.${tile.key}`)}</h1>
          </div>
        </a>
      );
    }
  } else {
    return (
      <div key={button.translationKey} className="tile">
        <div className="tile--disabled">
          <div className="tile--ribbon">{t('tiles.coming_soon')}</div>
        </div>
        <div className="tile--icon" style={iconStyle}>
          <SVG src={tile.iconData} />
        </div>
        <div className="tile--content">
          <h1>{t(`tiles.title.${tile.key}`)}</h1>
        </div>
      </div>
    );
  }
};

export default TileBase;
