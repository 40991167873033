import React from 'react';

export function Loader(): JSX.Element {
  return (
    <div className="text-center" style={{ marginTop: '10vh', paddingBottom: '10vh' }}>
      <div className="spinner-border" style={{ width: '4.5rem', height: '4.5rem' }}>
        <span className="sr-only">{'Loading...'}</span>
      </div>
    </div>
  );
}
