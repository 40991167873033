import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const acceptedLanguages = ['de', 'en', 'fr', 'pt', 'zh-CN', 'ja'];

export async function configureI18N(config?: Map<string, string>) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next); // passes i18n down to react-i18next
  await i18n.init({
    backend: {
      loadPath:
        // HACK: this problem only happens in local development, in deployment this is the same url
        config?.get('apiRoot') + 'api/v1/locales?lng={{lng}}&ns={{ns}}',
      allowMultiLoading: true,
      /* eslint-disable @typescript-eslint/no-explicit-any */
      parse: (data: string): any => {
        const response: any = JSON.parse(data);
        const languageData = {};
        response.result.terms.forEach((termEl: { context: string; term: any; translation: { content: any } }) => {
          const termKeys = termEl.context.split('.').map(key => JSON.parse(key));
          termKeys.push(termEl.term);
          const lastKey = termKeys.length - 1;
          termKeys.reduce((val: any, el, idx) => {
            if (idx === lastKey) {
              val[el] = termEl.translation.content;
              return val;
            }
            if (!val[el]) {
              val[el] = {};
            }
            return val[el];
          }, languageData);
        });
        return languageData;
      }
      /* eslint-enable @typescript-eslint/no-explicit-any */
    },
    fallbackLng: acceptedLanguages,
    supportedLngs: acceptedLanguages,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      order: ['cookie', 'localStorage', 'navigator'],
      lookupCookie: 'ServiceHubLng',
      caches: ['cookie', 'localStorage'],
      cookieMinutes: Number.MAX_VALUE,
      cookieDomain: config?.get('suiteOrigin')
    },
    defaultNS: 'haworth-servicehub',
    ns: 'haworth-servicehub'
  });
}

export default i18n;
