import i18n from 'i18next';
import React from 'react';
import './ServiceFooter.scss';

interface ServiceFooterProps {
  settingsUri: string;
  defaultLanguage: string;
}

export function ServiceFooter({ settingsUri, defaultLanguage }: ServiceFooterProps): JSX.Element {
  return (
    <service-footer selected-language={i18n.language || defaultLanguage} settings-uri={settingsUri}></service-footer>
  );
}
