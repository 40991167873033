import React from 'react';
import { useTranslation } from 'react-i18next';
import { TileSection as TileSectionModel } from '../../../shared/models/tile';
import Tile from '../tile/Tile';
import './TileSection.scss';
const TileSection = ({ section }: TileSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className="tilesection">
      <div className="tilesection--name">{t(`tiles.tilesection.${section.name}`)}</div>
      <div className="tilesection--body">
        {Array.from(section.tiles).map(tile => {
          tile.backgroundColor = tile.backgroundColor == null ? section.backgroundColor : tile.backgroundColor;
          tile.textColor = tile.textColor == null ? section.textColor : tile.textColor;
          return <Tile key={tile.key} tileConfig={tile}></Tile>;
        })}
      </div>
    </div>
  );
};

interface TileSectionProps {
  section: TileSectionModel;
}

export default TileSection;
