import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import App from './app/App';
import { register as registerServiceWorker } from './serviceWorker';
import { loadConfig, config } from './app/shared/config/Config';
import { configureI18N } from './app/i18n';
import './styles/app.scss';
import './register-web-components';
import './app/shared/analytics/analytics.service';
import { analyticsService } from './app/shared/analytics/analytics.service';
import { Loader } from './app/components/shared/Loader';
import CookieBotWeb from 'react-cookiebot';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const rootElement = document.getElementById('root');

if (rootElement) {
  Modal.setAppElement(rootElement);
  const initAppAsync = async () => {
    await configureI18N(config);

    analyticsService.init();

    const cookebotDomainGroupId = config.get('cookieBotDomainGroupId') || '';
    ReactDOM.render(
      <div>
        <CookieBotWeb domainGroupId={cookebotDomainGroupId} />
        <BrowserRouter basename={baseUrl}>
          <Suspense fallback={<Loader />}>
            <App />
          </Suspense>
        </BrowserRouter>
      </div>,
      rootElement
    );
    registerServiceWorker();
  };

  loadConfig().then(async function a() {
    initAppAsync();
  });
}
