import React, { useContext } from 'react';
import { useImage } from 'react-image';
import './ProfilePicture.scss';
import { UserContext } from '../../shared/UserContext';

function ProfilePicture() {
  const fallbackImage = '/assets/profile-placeholder.svg';
  const user = useContext(UserContext);
  const avatar = user?.userAvatar ?? '';

  console.log(avatar);

  const { src } = useImage({
    srcList: [avatar, '/assets/profile-placeholder.svg'],
    useSuspense: false
  });

  let className = 'profile-picture';
  if (src !== fallbackImage) {
    className += '--custom';
  }

  return <img className={className} src={src} alt="Profile" />;
}

export default ProfilePicture;
