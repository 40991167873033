import { config } from '../config/Config';
import { AxiosInstance, AxiosStatic } from 'axios';
import HttpClientCancelToken from './AxiosCancelToken';

/* eslint-disable @typescript-eslint/no-explicit-any */
class HttpClientBase {
  public _client: AxiosInstance;
  constructor(clientHttp: AxiosStatic) {
    this._client = clientHttp.create({
      baseURL: config.get('apiRoot')
    });
  }

  public static generateCancelToken(): HttpClientCancelToken {
    return new HttpClientCancelToken();
  }

  protected async get<T>(
    url: string,
    queryParams: Record<string, string> = {},
    cancelToken: HttpClientCancelToken = new HttpClientCancelToken(),
    headers?: any
  ) {
    return this._client.get<T>(url, { params: queryParams, headers, cancelToken: cancelToken.getToken() });
  }

  protected async post<T>(
    url: string,
    body: any,
    cancelToken: HttpClientCancelToken = new HttpClientCancelToken(),
    headers: any
  ) {
    return this._client.post<T>(url, body, { headers, cancelToken: cancelToken.getToken() });
  }

  protected async put<T>(
    url: string,
    body: any,
    cancelToken: HttpClientCancelToken = new HttpClientCancelToken(),
    headers: any
  ) {
    return this._client.put<T>(url, { ...body }, { headers, cancelToken: cancelToken.getToken() });
  }
}

export default HttpClientBase;
/* eslint-enable @typescript-eslint/no-explicit-any */
