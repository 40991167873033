import React from 'react';
import './HeroWelcome.scss';
import Greeter from '../greeter/Greeter';

const HeroWelcome = () => {
  const randomImgNumber = Math.floor(Math.random() * 4) + 1;
  const backgroundStyle = { backgroundImage: `url("/assets/HAW_Hero_${randomImgNumber}_optimized.jpg")` };
  return (
    <div style={backgroundStyle} className="herowelcome">
      <Greeter />
    </div>
  );
};

export default HeroWelcome;
