import i18n from 'i18next';
import React from 'react';
import { useEffect } from 'react';
interface ServiceBarProps {
  clientId: string;
  scope: string;
  settingsUri: string;
  defaultLanguages: string[];
  defaultLanguage: string;
}

export function ServiceBar({
  clientId,
  scope,
  settingsUri,
  defaultLanguages,
  defaultLanguage
}: ServiceBarProps): JSX.Element {
  useEffect(() => {
    customElements.whenDefined('service-bar').then(() => {
      const servicebarElement = document.querySelector('service-bar') as HTMLServiceBarElement;
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      servicebarElement.addEventListener('languageSet', (event: any) => {
        i18n.changeLanguage(event.detail);
      });
    });
  }, []);
  return (
    <service-bar
      class="servicebar--element"
      languages-json={JSON.stringify(i18n.languages || defaultLanguages)}
      shopping-cart-button-enabled={true}
      selected-language={i18n.language || defaultLanguage}
      style={{ fontSize: '16px' }}
      client-id={clientId}
      scope={scope}
      settings-uri={settingsUri}
    />
  );
}
