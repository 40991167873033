import { config } from '../config/Config';
import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module';

class GoogleTagManagerService {
  private tracking = false;
  private tagManagerArgs: TagManagerArgs | undefined;

  public async init() {
    const googleTagManagerTrackingId = config.get('googleTagManagerTrackingId') as string;
    if (googleTagManagerTrackingId) {
      console.log('googleTagManagerTrackingId: ', googleTagManagerTrackingId);
      this.tagManagerArgs = { gtmId: googleTagManagerTrackingId };
      TagManager.initialize(this.tagManagerArgs);
      this.tracking = true;
    }
  }

  public async pageView(pagePath: string, pageTitle: string) {
    if (this.tracking) {
      const data: DataLayerArgs = {
        dataLayer: {
          event: 'Pageview',
          pagePath: pagePath,
          pageTitle: pageTitle
        }
      };
      TagManager.dataLayer(data);
    }
  }
}

export const googleTagManagerService = new GoogleTagManagerService();
