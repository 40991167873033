import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { TokenService } from './auth/TokenService';
import { Loader } from '../components/shared/Loader';
import { UserContext } from './UserContext';

export function PrivateRoute({ ...rest }: RouteProps): JSX.Element {
  const userData = useContext(UserContext);

  if (userData == null || userData.userName == null) {
    const tokenService = new TokenService();
    tokenService.requestLogin();
    return <Loader />;
  } else {
    return <Route {...rest} />;
  }
}
