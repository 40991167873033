import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import './SubNav.scss';

const SubNav = () => {
  return (
    <div className="subnav--placeholder">
      <div className="subnav--element">
        <div className="subnav--logo">
          <a href="/" title="Home">
            <Logo /> <span className="subnav--text">ServiceHub</span>
          </a>
        </div>
        <div className="subnav--spacer"></div>
      </div>
    </div>
  );
};

export default SubNav;
