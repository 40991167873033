const config = new Map<string, string>();

async function loadConfig() {
  if (config.size <= 0) {
    const configResponse = await fetch('/config');
    const configValues = await configResponse.json();

    Object.keys(configValues).forEach(key => {
      config.set(key, configValues[key]);
    });
  }
}

export { config, loadConfig };
