import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './Greeter.scss';
import '../../shared/UserContext';
import { UserContext } from '../../shared/UserContext';
import ProfilePicture from '../profile-picture/ProfilePicture';

const Greeter = () => {
  const { t } = useTranslation();
  const getHour = () => {
    const date = new Date();
    const hour = date.getHours();
    return hour;
  };

  const user = useContext(UserContext);
  const userName = user?.userName;
  const currentHour = getHour();
  const greeting =
    currentHour > 12
      ? currentHour > 18
        ? t('greeter.good_evening')
        : t('greeter.good_afternoon')
      : t('greeter.good_morning');

  return (
    <div className="greeter">
      {userName && (
        <>
          <div className="greeter--profile">
            <ProfilePicture />
          </div>
          <div className="greeter--text">{`${greeting}${userName === null ? '.' : `, ${userName}`}`}</div>
        </>
      )}
    </div>
  );
};

export default Greeter;
