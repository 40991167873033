/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useReducer, useState, useContext, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext, UserContextData } from '../../shared/UserContext';
import './ServiceTicket.scss';
import { ReactComponent as CloseImg } from './close.svg';
import { ServiceTicketsService } from './ServiceTicketsService';
import isEmail from 'validator/lib/isEmail';

const ServiceTicket = ({ onCloseHandler }: ServiceTicketProps) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const getDefaultState = (localUser: UserContextData | null) => ({
    subject: '',
    companyCode: localUser?.userCompany || '',
    email: localUser?.userEmail || '',
    contactEmail: '',
    contactNumber: '',
    orderNumber: '',
    position: '',
    description: '',
    file: ''
  });
  const formReducer = (state: any, change: any) => {
    if (change.reset) {
      return getDefaultState(user);
    }
    return {
      ...state,
      [change.name]: change.value
    };
  };
  const [formData, setFormData] = useReducer(formReducer, {}, getDefaultState);
  const [validateSubject, setValidateSubject] = useState(false);
  const [subjectIsValid, setSubjectIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [validateDescription, setValidateDescription] = useState(false);
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);
  const [validateForm, setValidateForm] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmited] = useState(false);

  useEffect(() => {
    setFormData({ name: 'companyCode', value: user?.userCompany || '' });
    setFormData({ name: 'email', value: user?.userEmail || '' });
    if (!user?.userEmail) {
      setEmailIsValid(false);
    } else {
      setEmailIsValid(true);
    }
  }, [user]);

  const reset = () => {
    setFormData({
      reset: true
    });
    setValidateSubject(false);
    setSubjectIsValid(true);
    setValidateDescription(false);
    setDescriptionIsValid(true);
    setValidateForm(false);
    setFormIsValid(false);
    setSubmitForm(false);
    setSubmitting(false);
    if (fileRef !== null && fileRef.current !== null) {
      fileRef.current.value = '';
    }
  };

  useEffect(() => {
    if (!validateSubject) {
      return;
    }
    setSubjectIsValid(formData.subject);
    setValidateForm(true);
  }, [validateSubject, formData.subject]);

  useEffect(() => {
    if (!validateDescription) {
      return;
    }
    setDescriptionIsValid(formData.description);
    setValidateForm(true);
  }, [validateDescription, formData.description]);

  useEffect(() => {
    if (!validateForm) {
      return;
    }
    setFormIsValid(
      formData.subject && formData.description && emailIsValid && (formData.contactEmail || formData.contactNumber)
    );
  }, [
    validateForm,
    formData.subject,
    formData.description,
    formData.email,
    emailIsValid,
    formData.contactEmail,
    formData.contactNumber
  ]);

  useEffect(() => {
    if (submitting || !(submitForm && formIsValid)) {
      return;
    }

    setSubmitting(true);

    const form = new FormData();

    for (const property in formData) {
      form.append(property, formData[property]);
    }

    const ticketsService = new ServiceTicketsService();

    ticketsService.postSupportTicketEmail(form).then(() => {
      reset();
      setSubmited(true);
    });
  }, [formData, formIsValid, submitForm, submitting]);

  const handleChange = (event: any) => {
    setFormData({
      name: event.target.name,
      value: event.target.value
    });
  };
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      name: event.target.name,
      value: event.target.value
    });
    setEmailIsValid(isEmail(event.target.value));
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();

    setSubmitForm(true);
  };

  const setAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (event && event.target && event.target.files) {
      const selectedFile = event.target.files[0];
      setFormData({ name: 'file', value: selectedFile });
    }
  };

  return (
    <div className="serviceticket--container">
      <div className="serviceticket--close" onClick={() => onCloseHandler()}>
        <CloseImg />
      </div>
      <div className="serviceticket--header">
        <h1 className="mb-3">{t('service-ticket.title')}</h1>
      </div>

      {submitted ? (
        <div className="serviceticket--body">
          <div className="success-container">
            <p>{t('Your form has been submitted successfully')}</p>
          </div>
          <div className="footer">
            <button type="submit" onClick={() => onCloseHandler()}>
              {t('close')}
            </button>
          </div>
        </div>
      ) : (
        <>
          {' '}
          <form className="serviceticket--body" onSubmit={handleSubmit}>
            <section>
              <label htmlFor="subject" className="w-100">
                <p>
                  <span>{t('service-ticket.subject')}</span>
                  <strong>
                    <span title="required"> *</span>
                  </strong>
                </p>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  onBlur={() => setValidateSubject(true)}
                />
                {!subjectIsValid && (
                  <div className="validation-message">
                    {t('service-ticket.error_subject')}&nbsp;
                    <span role="img" aria-label="smile">
                      😊
                    </span>
                  </div>
                )}
              </label>
              <div className="flex">
                <label htmlFor="companyCode" className="w-50">
                  <p>
                    <span>{t('service-ticket.company_code')}</span>
                  </p>
                  <input
                    disabled={user?.userCompany !== undefined && user?.userCompany !== null}
                    type="text"
                    id="companyCode"
                    name="companyCode"
                    value={formData.companyCode}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="email" className="w-50">
                  <p>
                    <span>{t('service-ticket.email')}</span>
                    <strong>
                      <span title="required"> *</span>
                    </strong>
                  </p>
                  <input
                    disabled={user?.userEmail !== undefined && user?.userEmail !== null}
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleEmailChange}
                  />
                  {!emailIsValid && (
                    <div className="validation-message">
                      {t('service-ticket.error_email')}&nbsp;
                      <span role="img" aria-label="smile">
                        😊
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="flex">
                <label htmlFor="contactEmail" className="w-50">
                  <p>
                    <span>{t('ContactEmail')}</span>
                  </p>
                  <input
                    type="text"
                    id="contactEmail"
                    name="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="contactNumber" className="w-50">
                  <p>
                    <span>{t('Contact Number')}</span>
                  </p>
                  <input
                    type="text"
                    id="contactNumber"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="flex">
                <label htmlFor="orderNumber" className="w-50">
                  <p>
                    <span>{t('service-ticket.order_number')}</span>
                  </p>
                  <input
                    type="text"
                    id="orderNumber"
                    name="orderNumber"
                    value={formData.orderNumber}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="position" className="w-50">
                  <p>
                    <span>{t('service-ticket.position')}</span>
                  </p>
                  <input type="text" id="position" name="position" value={formData.position} onChange={handleChange} />
                </label>
              </div>
              <label htmlFor="description">
                <p>
                  <span>{t('service-ticket.description')}</span>
                  <strong>
                    <span title="required"> *</span>
                  </strong>
                </p>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  onBlur={() => setValidateDescription(true)}
                />
                {!descriptionIsValid && (
                  <div className="validation-message">
                    {t('service-ticket.error_description')}&nbsp;
                    <span role="img" aria-label="wink">
                      😉
                    </span>
                  </div>
                )}
              </label>
              <label htmlFor="file">
                <p>
                  <span>{t('service-ticket.attachment')}</span>
                </p>
                <input
                  type="file"
                  name="file"
                  id="file"
                  accept="image/*"
                  ref={fileRef}
                  onChange={e => setAttachment(e)}
                />
              </label>
            </section>
            <p className="required-legend">
              <strong>
                <span title="required"> *</span>
              </strong>
              <span> {t('service-ticket.required_fields')}</span>
            </p>
            <div className="footer">
              {submitting && <div>{t('service-ticket.submitting_request')}</div>}
              <button type="submit" disabled={!formIsValid || submitting}>
                {t('service-ticket.submit')}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
interface ServiceTicketProps {
  onCloseHandler: Function;
}
export default ServiceTicket;
