import ApiService from '../../../shared/http/ApiService';
import { Tile, TileSection } from '../../../shared/models/tile';

export class TileService {
  private readonly apiService: ApiService;
  private readonly tilesApi = 'api/v1/tiles';

  constructor() {
    this.apiService = new ApiService();
  }

  public async getTilesConfiguration(): Promise<Map<string, TileSection>> {
    const sections = new Map<string, TileSection>();
    const response = await this.apiService.get<Tile[]>(this.tilesApi);
    response.data.forEach(tile => {
      if (sections.has(tile.tileSection.id)) {
        const section = sections.get(tile.tileSection.id);
        if (section) {
          section.tiles.push(tile);
        }
      } else {
        const tileSection = tile.tileSection;
        tileSection.tiles = [tile];
        sections.set(tile.tileSection.id, tileSection);
      }
    });
    return sections;
  }
}
