import axios, { CancelTokenSource, CancelToken } from 'axios';
import HttpClientErrors from './HttpClientErrors';

class HttpClientCancelToken {
  public sourceToken: CancelTokenSource;
  constructor() {
    this.sourceToken = axios.CancelToken.source();
  }

  public getToken(): CancelToken {
    return this.sourceToken.token;
  }

  public cancel(): void {
    this.sourceToken.cancel(HttpClientErrors.cancelRequest);
  }
}

export default HttpClientCancelToken;
