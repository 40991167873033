import React, { useState, useEffect } from 'react';
import { TileSection as TileSectionModel } from '../../../shared/models/tile';
import { TileService } from './TileService';
import TileSection from '../tilesection/TileSection';
import './TileGrid.scss';
import { Loader } from '../../shared/Loader';

const TileGrid: React.FunctionComponent<{}> = props => {
  const [sections, setSections] = useState<Map<string, TileSectionModel> | null>(null);
  useEffect(() => {
    const tileService = new TileService();
    const loadData = async () => {
      const tiles = await tileService.getTilesConfiguration();
      setSections(tiles);
    };
    loadData();
  }, []);

  return sections ? (
    <div className="tilegrid">
      {Array.from(sections).map(([key, section]) => (
        <TileSection key={key} section={section} />
      ))}
    </div>
  ) : (
    <div className="tilegrid">
      <Loader />
    </div>
  );
};

export default TileGrid;
